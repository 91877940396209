// import EntryProxy from '@/app/proxies/TipsProxy';
import Vue from 'vue';

export default {
    namespaced: true,
    state: {
        sequence: [],
        index: 0,
        tips: [],
        last: 0
    },

    getters: {
        tips: state =>
        {
            return state.tips;
        },
        randomTip: state => () =>
        {
            return state.tips[ state.sequence[ state.index ] ];
        },
        specificTip: state => ( id ) =>
        {
            for( let i in state.tips )
            {
                if( state.tips[ i ].id === parseInt( id, 10 ) ) return state.tips[ i ];
            }
            return null;
        }
    },

    mutations: {
        indexIncrement: ( state, payload ) =>
        {
            state.index++;
            if( state.index > state.tips.length - 1 )
            {
                state.index = 0;
            }
        },

        tips: ( state, payload ) =>
        {
            console.log( 'old tips', state.tips.length );
            Vue.set( state, 'tips', payload );
            let sequence = [ ...Array( payload.length ).keys() ];
            shuffle( sequence );
            state.sequence = sequence;
            state.index = 0;
            state.last = new Date().getTime();
            console.log( 'new tips', state.tips.length );
        },

        reset: ( state, payload ) =>
        {
            console.log( 'reset', state.tips.length );
            state.tips.splice( 0, state.tips.length );
            state.sequence.splice( 0, state.sequence.length );
            state.index = 0;
            state.last = 0;
        }
    },

    actions: {
        load: ( context, payload ) =>
        {
            /*
            if( context.state.tips.length !== data.length )
            // reshuffle
            */
           let cats = [];
           for( let i in context.rootState.categories )
           {
                if( context.rootState.categories[ i ] )
                {
                    cats.push( i );
                }
           }
           if( context.state.tips.length < 1 || context.state.last < ( new Date().getTime() - ( 60 * 60 * 1000 ) ) )
           {
               console.log( 'fetch new', context.state.tips.length, context.state.last );
                return Vue.$http.get( window._rootData.apiUrl + '/api/tips?categories=' + cats.join( ',' ) ).then( ( response ) =>
                {
                    console.log( 'fetched new', context.state.tips.length, context.state.last );
                    context.commit( 'tips', response.data );
                });
            }
        },
        next( context )
        {
            context.commit( 'indexIncrement' );
        },
        reset( context )
        {
            context.commit( 'reset' );
        }
    }
};

function shuffle( array )
{
    array.sort( () => Math.random() - 0.5 );
}
