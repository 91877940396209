/* eslint-disable */
import VeeValidate from 'vee-validate';

VeeValidate.Validator.extend( 'date_valid', {
    getMessage: field => 'The ' + field + ' value is not truthy.',
    validate: value =>
    {
        let parts = value.split( '-' );
        let day = parseInt( parts[ 2 ] );
        let month = parseInt( parts[ 1 ] - 1 );
        let year = parseInt( parts[ 0 ] );
        let date = new Date( year, month, day, 0, 0, 0 );

        return day === date.getDate() && month === date.getMonth() && year === date.getFullYear();
    }
});

VeeValidate.Validator.extend( 'date_before', {
    getMessage: ( field, val ) => 'The ' + field + ' value must be earlier than minDate',
    validate: value =>
    {
        let parts = value.split( '-' );
        let day = parseInt( parts[ 2 ] );
        let month = parseInt( parts[ 1 ] - 1 );
        let year = parseInt( parts[ 0 ] );
        let date = new Date( year, month, day, 0, 0, 0 );

        let before = new Date();
        before.setYear( 2001 );

        return ( before - date >= 0 );
    }
});

export default {
    firstname: { required: true },
    gender: { required: true },
    surname: { required: false, min: 2 },
    optin: { required: true },
    prize: { required: true },
    date_of_birth: { required: true, date_valid: true, date_before: true },
    telephone: { required: true, regex: /^((\+|00(\s|\s?\-\s?)?)31(\s|\s?\-\s?)?(\(0\)[\-\s]?)?|0)[1-9]((\s|\s?\-\s?)?[0-9])((\s|\s?-\s?)?[0-9])((\s|\s?-\s?)?[0-9])\s?[0-9]\s?[0-9]\s?[0-9]\s?[0-9]\s?[0-9]$/ }
};
