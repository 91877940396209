import Vue from 'vue';
import Vuex from 'vuex';

import entry from './modules/entry';
import tips from './modules/tips';
import createPersistedState from 'vuex-persistedstate';

Vue.use( Vuex );
require( 'es6-promise' ).polyfill();

export default new Vuex.Store({
    strict: true,

    plugins: [ createPersistedState({
        key: 'kleurjeroutine',
        paths: [
            'categories',
            'tips'
        ]
    }) ],

    modules: {
        entry,
        tips
    },

    state: {
        linkad: false,
        categories: {
            work: true,
            food: true,
            move: true,
            relax: true
        }
    },

    getters: {
        categories: ( state ) =>
        {
            return state.categories;
        },
        linkad: ( state ) =>
        {
            return state.linkad;
        }
    },

    mutations: {
        setActiveCategories( state, payload )
        {
            for( let i in state.categories )
            {
                state.categories[ i ] = payload.indexOf( i ) >= 0;
            }
        },
        toggle( state, payload )
        {
            let total = 0;
            for( let i in state.categories )
            {
                if( state.categories[ i ] )
                {
                    total++;
                }
            }
            state.categories[ payload ] = ( state.categories[ payload ] && total > 1 ) || !state.categories[ payload ] ? !state.categories[ payload ] : true;
        },
        linkad( state, payload )
        {
            state.linkad = payload;
        }
    },

    actions: {
        setActiveCategories( context, payload )
        {
            context.commit( 'setActiveCategories', payload );
            context.dispatch( 'tips/reset' );
        },
        toggleCategory( context, payload )
        {
            context.commit( 'toggle', payload );
        },
        linkad( context, payload )
        {
            context.commit( 'linkad', payload );
        }
    }
});
