<template>
    <div :class="{ 'main-layout': true, linkad: $store.getters.linkad }">
        <img src="/static/img/campaign-logo.png" alt="" class="campaign-logo" />
        <a href="#" @click.prevent="showInfo()" class="info-btn" v-if="!infoOpen">info</a>
        <a href="#" @click.prevent="closeInfo()" class="info-btn" v-else>X</a>
        <div class="content">
            <slot></slot>
        </div>
        <div class="info-panel" ref="infoPanel">
            <div class="waves">
                <div class="wave" ref="wave1"></div>
                <div class="wave" ref="wave2"></div>
                <div class="wave" ref="wave3"></div>
            </div>
            <div class="info-content">
                <a href="https://www.spa.nl/" target="_blank"><img src="/static/img/spa-logo.png" alt="" class="logo" /></a>
                <h2>KLEUR JE ROUTINE<br />MET ÉÉN DRUK OP DE DOP</h2>
                <p>Een keer iets nieuws doen of proberen is lekker verfrissend. Daarom kleurt SPA® niet alleen je drinkroutine, maar ook andere routines in je leven. Met één druk op de dop krijg je een variatietip. Daarmee doorbreek je de sleur en geef je jouw routines kleur.</p>
                <p>Waar wacht je nog op? Druk snel op die <a href="/">blauwe dop</a>!</p>
                <p>Ben je geïnspireerd of heb je zelf een originele variatietip?<br />Deel ‘m dan met #kleurjeroutine en #spanederland.</p>
                <p><a href="https://www.spa.nl/" target="_blank">spa.nl</a></p>
                <img src="/static/img/campaign-logo-black.png" alt="" class="logo" />
                <img src="/static/img/bottles.jpg" alt="" class="bottles" />
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>

</style>

<script>
    import Vue from 'vue';
    import { TweenMax } from 'gsap';
    export default {
        data()
        {
            return {
                infoOpen: false
            };
        },

        mounted()
        {
            Vue.nextTick( () =>
            {
                let x = 2179 / 50 * ( window.maxbox * 0.05 );
                console.log( x );

                TweenMax.to( this.$refs.wave1, 30 / 1080 * window.minbox, { backgroundPositionX: -x, repeat: -1, yoyo: true, ease: Linear.easeNone });
                TweenMax.to( this.$refs.wave2, 45 / 1080 * window.minbox, { backgroundPositionX: x, repeat: -1, yoyo: true, ease: Linear.easeNone });
                TweenMax.to( this.$refs.wave3, 60 / 1080 * window.minbox, { backgroundPositionX: -x, repeat: -1, yoyo: true, ease: Linear.easeNone });
            });
        },

        methods: {
            showInfo()
            {
                window.dataLayer.push({ 'event': 'showinfo-click' });
                this.infoOpen = true;
                TweenMax.to( this.$refs.infoPanel, 0.5, { top: window.maxbox * 0.15, ease: Sine.easeOut });
            },
            closeInfo()
            {
                window.dataLayer.push({ 'event': 'hideinfo-click' });
                this.infoOpen = false;
                TweenMax.to( this.$refs.infoPanel, 0.25, { top: window.maxbox * 0.92, ease: Sine.easeOut });
            }
        }
    };
</script>
