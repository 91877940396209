export default {
    attributes: {
        gender: 'Aanhef',
        prize: 'Prijs',
        firstname: 'Voornaam',
        infixname: 'Tussenv.',
        surname: 'Achternaam',
        zipcode: 'Postcode',
        house_num: 'Huisnr.',
        house_num_add: 'Toev.',
        telephone: 'Telefoonnummer',
        telephone_optin: 'Deze checkbox',
        emailaddress: 'E-mailadres',
        date_of_birth: 'Geboortedatum',
        kit: 'Deze checkbox',
        terms: 'Deze checkbox',
        iban: 'Iban',
        machtiging: 'Deze checkbox',
        street: 'Straatnaam',
        city: 'Woonplaats'
    },
    custom: {
        telephone_optin: {
            required: 'Deze checkbox is verplicht als je een telefoonnummer invult.'
        },

        date_of_birth: {
            date_before: 'Je moet minstens 18 jaar zijn om mee te doen.'
        }
    }
};
